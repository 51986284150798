import React, { useState } from "react"

// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

import { Container, Figure } from "react-bootstrap"

import AreYouWinner from "../components/are-you-winner"
// Star Backgrounds - Mobile
import StarsLeftTopM from "../images/stars-left-top-m.png"
import StarsLeftBottomM from "../images/stars-left-bottom-m.png"
import StarsRightTopM from "../images/stars-right-top-m.png"
import StarsRightBottomM from "../images/stars-right-bottom-m.png"

// Star Backgrounds - Desktop
import StarsLeft from "../images/stars-left.png"
import StarsRight from "../images/stars-right.png"

// configureAnchors({ offset: -20 })
const NotAWinner = props => {
  //console.log(props.countryCode)
  return (
    <>
      {/* <ScrollableAnchor id={"main"}> */}
      <section className="main-content py-4 py-md-5 position-relative">
        <Container>
          <div className="text-white px-2">
            {/* <h2 className="text-center text-uppercase my-3">
                Sorry You haven't won a prize this time,{" "}
                <span className="d-lg-block">
                  But you can try again another day!
                </span>
              </h2> */}

            {props.message === "" ? (
              <h2 className="text-center text-uppercase my-3">
                Sorry You haven't won a prize this time,{" "}
                <span className="d-lg-block">
                  But you can try again another day!
                </span>
              </h2>
            ) : props.message ===
              "Sorry this is an invalid code, please try re-entering" ? (
              <h2 className="text-center text-uppercase my-3">
                SORRY WE DON’T RECOGNISE THAT CODE, PLEASE{" "}
                <u
                  onClick={() => {
                    props.setAreYouWinner({
                      state: true,
                      countryCode: props.countryCode,
                    })
                    // console.log(props.countryCode)
                    props.setNotAWinner(false)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  TRY AGAIN
                </u>
              </h2>
            ) : (
              <h2 className="text-center text-uppercase my-3 mx-4">
                {props.message}
              </h2>
            )}
          </div>
        </Container>

        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={StarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={StarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={StarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image src={StarsRightBottomM} alt="Stars Right Bottom" />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 stars-left position-absolute">
            <Figure.Image src={StarsLeft} alt="Stars Left" />
          </Figure>
          <Figure className="mb-0 stars-right position-absolute">
            <Figure.Image src={StarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </section>
      {/* </ScrollableAnchor> */}
    </>
  )
}

export default NotAWinner
