import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/MMSAmazonComponents/components/layout"
import SEO from "../../components/MMSAmazonComponents/components/seo"
import Agegate from "../../components/MMSAmazonComponents/components/agegate"
import CountrySelection from "../../components/MMSAmazonComponents/components/country-selection"
import RoiQuestion from "../../components/MMSAmazonComponents/components/roi-question"
import AreYouWinner from "../../components/MMSAmazonComponents/components/are-you-winner"

import WinnerTVSoundbar from "../../components/MMSAmazonComponents/components/winner-tv-soundbar"
import WinnerFireTVStick from "../../components/MMSAmazonComponents/components/winner-fire-tv-stick"
import WinnerMmsBundle from "../../components/MMSAmazonComponents/components/winner-mms-bundle"
import WinnerFreePack from "../../components/MMSAmazonComponents/components/winner-free-pack"

import WinnerDetailsSubmittedConfirmation from "../../components/MMSAmazonComponents/components/winner-details-submitted-confirmation"

import NotAWinner from "../../components/MMSAmazonComponents/components/not-a-winner"

const IndexPage = () => {
  // const [oneTrust, setOneTrust] = useState(false)
  const [ageGateShow, setAgeGateShow] = useState(true)
  const [countrySelection, setCountrySelection] = useState(false)
  const [roiQuestion, setRoiQuestion] = useState({
    state: false,
    countryCode: "",
  })
  const [areYouWinner, setAreYouWinner] = useState({
    state: false,
    countryCode: "",
  })
  const [winnerTVSoundbar, setWinnerTVSoundbar] = useState({
    state: false,
    id: 0,
    category: "",
  })
  const [winnerFireTVStick, setWinnerFireTVStick] = useState({
    state: false,
    id: 0,
    category: "",
  })
  const [winnerMmsBundle, setWinnerMmsBundle] = useState({
    state: false,
    id: 0,
    category: "",
  })
  const [winnerFreePack, setWinnerFreePack] = useState({
    state: false,
    id: 0,
    category: "",
  })

  const [
    winnerDetailsSubmittedConfirmation,
    setWinnerDetailsSubmittedConfirmation,
  ] = useState(false)
  const [notAWinner, setNotAWinner] = useState({
    state: false,
    message: "",
    countryCode: "",
  })

  let ageCookie = new Cookies()
  useEffect(() => {
    if (ageCookie.get("isOldEnough") === "yes") {
      setAgeGateShow(false)
      setCountrySelection(true)
    } else {
      ageCookie.set("isOldEnough", "no", <Link to="/#main" />)
      setAgeGateShow(true)
    }

    console.log(window.OnetrustActiveGroups)
  }, [])
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../../MMSAmazon/css/app.css" />
        <link rel="stylesheet" href="../../MMSAmazon/css/responsive.css" />
      </Helmet>
      <Layout
        pageInfo={{ pageName: "index" }}
        ageGateShow={ageGateShow}
        countrySelection={countrySelection}
        roiQuestion={roiQuestion.state}
        areYouWinner={areYouWinner}
        winnerMmsBundle={winnerMmsBundle}
        setAreYouWinner={setAreYouWinner}
        setWinnerTVSoundbar={setWinnerTVSoundbar}
        setWinnerFireTVStick={setWinnerFireTVStick}
        setWinnerMmsBundle={setWinnerMmsBundle}
        setWinnerFreePack={setWinnerFreePack}
        setNotAWinner={setNotAWinner}
        setAgeGateShow={setAgeGateShow}
        setWinnerDetailsSubmittedConfirmation={
          setWinnerDetailsSubmittedConfirmation
        }
      >
        <SEO
          title="Amazon prime UK"
          keywords={[`gatsby`, `react`, `bootstrap`]}
        />
        {/* {oneTrust && <h1>One Trust</h1>} */}
        {ageGateShow && (
          <Agegate
            setAgeGateShow={setAgeGateShow}
            setCountrySelection={setCountrySelection}
          />
        )}
        {countrySelection && (
          <CountrySelection
            setCountrySelection={setCountrySelection}
            setRoiQuestion={setRoiQuestion}
            setAreYouWinner={setAreYouWinner}
          />
        )}
        {roiQuestion.state && (
          <RoiQuestion
            setRoiQuestion={setRoiQuestion}
            setAreYouWinner={setAreYouWinner}
            countryCode={roiQuestion.countryCode}
          />
        )}
        {areYouWinner.state && (
          <AreYouWinner
            setAreYouWinner={setAreYouWinner}
            setWinnerTVSoundbar={setWinnerTVSoundbar}
            setWinnerFireTVStick={setWinnerFireTVStick}
            setWinnerMmsBundle={setWinnerMmsBundle}
            setWinnerFreePack={setWinnerFreePack}
            setNotAWinner={setNotAWinner}
            countryCode={areYouWinner.countryCode}
          />
        )}
        {winnerTVSoundbar.state && (
          <WinnerTVSoundbar
            customerID={winnerTVSoundbar.id}
            PrizeCategory={winnerTVSoundbar.category}
            setWinnerTVSoundbar={setWinnerTVSoundbar}
            setWinnerDetailsSubmittedConfirmation={
              setWinnerDetailsSubmittedConfirmation
            }
          />
        )}
        {winnerFireTVStick.state && (
          <WinnerFireTVStick
            customerID={winnerFireTVStick.id}
            PrizeCategory={winnerFireTVStick.category}
            setWinnerFireTVStick={setWinnerFireTVStick}
            setWinnerDetailsSubmittedConfirmation={
              setWinnerDetailsSubmittedConfirmation
            }
          />
        )}
        {winnerMmsBundle.state && (
          <WinnerMmsBundle
            customerID={winnerMmsBundle.id}
            PrizeCategory={winnerMmsBundle.category}
            setWinnerMmsBundle={setWinnerMmsBundle}
            setWinnerDetailsSubmittedConfirmation={
              setWinnerDetailsSubmittedConfirmation
            }
          />
        )}
        {winnerFreePack.state && (
          <WinnerFreePack
            customerID={winnerFreePack.id}
            PrizeCategory={winnerFreePack.category}
            setWinnerFreePack={setWinnerFreePack}
            setWinnerDetailsSubmittedConfirmation={
              setWinnerDetailsSubmittedConfirmation
            }
          />
        )}

        {winnerDetailsSubmittedConfirmation && (
          <WinnerDetailsSubmittedConfirmation />
        )}

        {notAWinner.state && (
          <NotAWinner
            message={notAWinner.message}
            setAreYouWinner={setAreYouWinner}
            setNotAWinner={setNotAWinner}
            countryCode={notAWinner.countryCode}
          />
        )}
      </Layout>
    </>
  )
}

export default IndexPage
