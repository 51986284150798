import React, { useState, useEffect } from "react"

import Cookies from "universal-cookie"

// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

import { Container, Figure, Form, Button } from "react-bootstrap"

import mmsRed from "../images/mms-red.png"
import mmsPurple from "../images/mms-purple.png"
import mmsYellow from "../images/mms-yellow.png"

// Star Backgrounds - Mobile
import RoiStarsLeftTopM from "../images/stars-left-top-m.png"
import RoiStarsLeftBottomM from "../images/stars-left-bottom-m.png"
import RoiStarsRightTopM from "../images/stars-right-top-m.png"
import RoiStarsRightBottomM from "../images/stars-right-bottom-m.png"

// Star Backgrounds - Desktop
import RoiStarsLeftTop from "../images/roi-stars-left-top.png"
import RoiStarsLeftCenter from "../images/roi-stars-left-center.png"
import RoiStarsLeftBottom from "../images/roi-stars-left-bottom.png"
import RoiStarsRightTop from "../images/roi-stars-right-top.png"
import RoiStarsRightBottom from "../images/roi-stars-right-bottom.png"

// configureAnchors({ offset: -20 })

const RoiQuestion = props => {
  const [wrongAnswerMessage, setWrongAnswerMessage] = useState(false)
  const [selectQuestionError, setSelectQuestionError] = useState(false)
  const [imgState, setImgState] = useState(false)

  useEffect(() => {
    const red = new Image()
    red.onload = () => {
      setImgState(true)
    }
    const purple = new Image()
    purple.onload = () => {
      setImgState(true)
    }
    const yellow = new Image()
    yellow.onload = () => {
      setImgState(true)
    }
    red.src = mmsRed
    purple.src = mmsPurple
    yellow.src = mmsYellow
  }, [])

  let ageCookie = new Cookies()
  let isCountrySelected = new Cookies()

  const onClickHandleSubmit = event => {
    event.preventDefault()
    var ele = document.getElementsByTagName("input")
    for (var i = 0; i < ele.length; i++) {
      if ((ele[i].type = "radio")) {
        if (ele[i].checked) {
          if (ele[i].value === "Purple") {
            setSelectQuestionError(false)
            setWrongAnswerMessage(false)
            props.setRoiQuestion({
              state: false,
              countryCode: props.countryCode
            })
            props.setAreYouWinner({
              state: true,
              countryCode: props.countryCode
            })
            if (ageCookie.get("isOldEnough") === "yes") {
              isCountrySelected.set("isCountrySelected", "IEAnswered", {
                path: "/",
                maxAge: 2592000
                // secure: true,
                // httpOnly: true,
                // sameSite: true,
              })
            }
          } else {
            setSelectQuestionError(false)
            setWrongAnswerMessage(true)
          }
        }
      }
    }
    if (
      ele[0].checked === false &&
      ele[1].checked === false &&
      ele[2].checked === false
    ) {
      setSelectQuestionError(true)
      setWrongAnswerMessage(false)
    }
  }
  const onChangeHandler = () => {
    setSelectQuestionError(false)
    setWrongAnswerMessage(false)
  }
  return (
    <>
      {imgState && (
        // <ScrollableAnchor id={"main"}>
          <section className="main-content py-4 py-md-5 position-relative">
            <Container>
              <div className="text-white px-2">
                <h1 className="text-center text-uppercase heading py-md-4 mb-0">
                  Which of the below colours{" "}
                  <span className="d-md-block">
                    is not an M&amp;M's<sup>&reg;</sup> Character?
                  </span>
                </h1>

                <Form className="roi-form" onSubmit={onClickHandleSubmit}>
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input opacity-0"
                        id="mmsRed"
                        name="roi-question"
                        value="Red"
                        onChange={onChangeHandler}
                      />
                      <label className="custom-control-label" htmlFor="mmsRed">
                        <div className="text-center">
                          <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                            <Figure.Image src={mmsRed} alt="MMS Red" />
                          </Figure>
                          <h3 className="text-center text-uppercase my-3">
                            Red
                          </h3>
                        </div>
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input opacity-0"
                        id="mmsPurple"
                        name="roi-question"
                        value="Purple"
                        onChange={onChangeHandler}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="mmsPurple"
                      >
                        <div className="text-center">
                          <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                            <Figure.Image src={mmsPurple} alt="MMS Purple" />
                          </Figure>
                          <h3 className="text-center text-uppercase my-3">
                            Purple
                          </h3>
                        </div>
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input opacity-0"
                        id="mmsYellow"
                        name="roi-question"
                        value="Yellow"
                        onChange={onChangeHandler}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="mmsYellow"
                      >
                        <div className="text-center">
                          <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                            <Figure.Image src={mmsYellow} alt="MMS Yellow" />
                          </Figure>
                          <h3 className="text-center text-uppercase my-3">
                            Yellow
                          </h3>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <Button
                      variant="dark"
                      size="lg"
                      className="rounded-pill text-uppercase"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>

                  {wrongAnswerMessage && (
                    <h4 className="error text-center text-uppercase mt-4 mb-0">
                      Oops, Wrong Answer. Please try again.
                    </h4>
                  )}

                  {selectQuestionError && (
                    <h4
                      className="error text-center text-uppercase mt-4 mb-0"
                      style={{ color: "#ffd204" }}
                    >
                      Please select atleast one
                    </h4>
                  )}
                </Form>
              </div>
            </Container>

            {/* Background Star Images - Mobile */}
            <div className="d-md-none">
              <Figure className="mb-0 stars-left-top-m position-absolute">
                <Figure.Image src={RoiStarsLeftTopM} alt="Stars Left Top" />
              </Figure>
              <Figure className="mb-0 stars-left-center-m position-absolute">
                <Figure.Image
                  src={RoiStarsLeftBottomM}
                  alt="Stars Left Center"
                />
              </Figure>
              <Figure className="mb-0 stars-left-bottom-m position-absolute">
                <Figure.Image
                  src={RoiStarsLeftBottomM}
                  alt="Stars Left Bottom"
                />
              </Figure>
              <Figure className="mb-0 stars-right-top-m position-absolute">
                <Figure.Image src={RoiStarsRightTopM} alt="Stars Right Top" />
              </Figure>
              <Figure className="mb-0 stars-right-center-m position-absolute">
                <Figure.Image
                  src={RoiStarsRightBottomM}
                  alt="Stars Right Center"
                />
              </Figure>
              <Figure className="mb-0 stars-right-bottom-m position-absolute">
                <Figure.Image
                  src={RoiStarsRightBottomM}
                  alt="Stars Right Bottom"
                />
              </Figure>
            </div>

            {/* Background Star Images - Desktop */}
            <div className="d-none d-md-block">
              <Figure className="mb-0 roi-stars-left-top position-absolute">
                <Figure.Image src={RoiStarsLeftTop} alt="Stars Left Top" />
              </Figure>
              <Figure className="mb-0 roi-stars-left-center position-absolute">
                <Figure.Image
                  src={RoiStarsLeftCenter}
                  alt="Stars Left Center"
                />
              </Figure>
              <Figure className="mb-0 roi-stars-left-bottom position-absolute">
                <Figure.Image
                  src={RoiStarsLeftBottom}
                  alt="Stars Left Bottom"
                />
              </Figure>
              <Figure className="mb-0 roi-stars-right-top position-absolute">
                <Figure.Image src={RoiStarsRightTop} alt="Stars Right Top" />
              </Figure>
              <Figure className="mb-0 roi-stars-right-bottom position-absolute">
                <Figure.Image
                  src={RoiStarsRightBottom}
                  alt="Stars Right Bottom"
                />
              </Figure>
            </div>
          </section>
        // </ScrollableAnchor>
      )}
    </>
  )
}

export default RoiQuestion
