import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"

// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

import { Container, Row, Col, Figure, Form, Button } from "react-bootstrap"

// import ReCAPTCHA from "react-google-recaptcha"

// import axios from "axios"

import FormStarsLeftTop from "../images/form-stars-left-top.png"
import FormStarsLeftCenter from "../images/form-stars-left-center.png"
import FormStarsLeftBottom from "../images/form-stars-left-bottom.png"

import FormStarsRightTop from "../images/form-stars-right-top.png"
import FormStarsRightCenter from "../images/form-stars-right-center.png"
import FormStarsRightBottom from "../images/form-stars-right-bottom.png"

import AmazonBundle from "../images/amazon-bundle.png"
// configureAnchors({ offset: -20 })

const WinnerMmsBundle = props => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log("Timer")
  //   }, 1000)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [])
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errormsg, setErrorMsg] = useState(false)
  const [captchaResponse, setCaptchaResponse] = useState("")
  const recaptchaRef = useRef()
  let btnRef = useRef()
  var formData
  const onInvalid = e => {
    e.target.setCustomValidity("Please Enter a Valid Contact Number")
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  // const timer = () =>
  //   setTimeout(() => {
  //     //btnRef.current.setAttribute("disabled", "disabled")
  //     return <LoadingIndicator />
  //   }, 30000)
  const onSubmit = data => {
    // if (captchaValue === true) {
      // if (btnRef.current) {
      //   btnRef.current.setAttribute("disabled", "disabled")
      // }
      // setLoading(true)
      // data.customerID = props.customerID
      // data.PrizeCategory = props.PrizeCategory
      // data.packingCode = captchaResponse
      // formData = data
      // console.log(data)

      // const username = "AuthenticatedAPIUser"
      // const password = "mrePi@KU_IOR_Cnmapiga"
      // const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      //   "base64"
      // )
      // const postHeaders = {
      //   headers: {
      //     "Content-Type": "application/json; charset=utf-8",
      //     "Access-Control-Allow-Origin": "*",
      //     // Authorization: `Basic ${token}`,
      //     // "X-ApiKey": "eyGhjrtnkUyQxzplkjytgnmlqDavtUkaCbrQ",
      //   },
      // }
      // axios
      //   .post(
      //     //stage
      //     "https://stage.api.mms-streaming.co.uk/APMBAPI/InsertWinnerAddress",
      //     //prod
      //     // "https://api.mms-streaming.co.uk/APMBAPI/InsertWinnerAddress",
      //     formData,
      //     postHeaders
      //   )
      //   .then(response => {
      //     // console.log(response)
      //     if (response.data.intReturnParameters === 1) {
            props.setWinnerMmsBundle({ state: false, id: 0 })
            props.setWinnerDetailsSubmittedConfirmation(true)
    //         setLoading(false)
    //       } else {
    //         setLoading(false)
    //         //props.setWinnerMmsBundle({ state: false, id: 0 })
    //         // props.setWinnerDetailsSubmittedConfirmation(false)
    //         setErrorMsg(true)
    //         // reset()
    //       }
    //     })
    //     .catch(error => {
    //       // console.log(error)
    //       // console.log({ formData })
    //       setLoading(false)
    //       setCaptchaError(true)
    //       recaptchaRef.current.reset()
    //     })
    // } else {
    //   setCaptchaError(true)
    // }
  }
  const onChange = value => {
    // console.log(value)
    setCaptchaValue(true)
    setCaptchaError(false)
    setCaptchaResponse(value)
  }
  const onExpiredCaptcha = () => {
    setCaptchaValue(false)
    setCaptchaError(true)
  }

  return (
    <>
      {loading && <div className="loading"></div>}

      {/* <ScrollableAnchor id={"main"}> */}
        <section className="main-content py-4 position-relative">
          <Container>
            <div className="text-white px-2">
              <h1 className="text-center text-uppercase heading heading-lg mb-0">
                Winner!
              </h1>

              <h2 className="text-center text-uppercase my-4">
                Congrats,{" "}
                <span className="d-md-block">
                  You've won an M&amp;M's<sup>&reg;</sup> Bundle. TO CLAIM YOUR
                  PRIZE, JUST FILL IN THE DETAILS BELOW
                </span>
              </h2>
              <div className="text-center">
                <img
                  className="w-md-50 w-100 mb-4"
                  src={AmazonBundle}
                  alt="AmazonBundle prize"
                />
              </div>
              <Form
                className="form-winner"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <Row noGutters>
                  <Col xl={{ span: 8, offset: 2 }}>
                    <div className="winner-form text-dark p-4">
                      <Form.Group controlId="houseName" className="mb-4">
                        <Form.Label>House Name</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="50"
                          className="rounded-0"
                          {...register("houseName")}
                        />
                        {errors.houseName && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group>

                      <Form.Group controlId="houseNumber" className="mb-4">
                        <Form.Label>House Number *</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="20"
                          className="rounded-0"
                          {...register("houseNumber", { required: true })}
                        />
                        {errors.houseNumber && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group>

                      <Form.Group controlId="street" className="mb-4">
                        <Form.Label>Street *</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="100"
                          className="rounded-0"
                          {...register("street", { required: true })}
                        />
                        {errors.street && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group>

                      <Form.Group controlId="town" className="mb-4">
                        <Form.Label>Town *</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="100"
                          className="rounded-0"
                          {...register("town", { required: true })}
                        />
                        {errors.town && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group>

                      {/* <Form.Group controlId="city" className="mb-4">
                        <Form.Label>City *</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="100"
                          className="rounded-0"
                          {...register("city", { required: true })}
                        />
                        {errors.city && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group> */}

                      <Form.Group controlId="postalCode" className="mb-4">
                        <Form.Label>Postal code *</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="50"
                          className="rounded-0"
                          {...register("postalCode", { required: true })}
                        />
                        {errors.postalCode && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group>

                      {/* <Form.Group controlId="phoneNumber" className="mb-4">
                        <Form.Label>Contact phone number *</Form.Label>
                        <Form.Control
                          //type="text"
                          maxLength="50"
                          className="rounded-0"
                          pattern="[0-9\-()]{1,50}"
                          onChange={onInvalid}
                          {...register("contactNumber", { required: true })}
                        />
                        {errors.contactNumber && (
                          <span className="input-error">Field is required</span>
                        )}
                      </Form.Group> */}

                      {/* <Form.Group
                      controlId="robot"
                      className="custom-control custom-checkbox text-center"
                    >
                      <Form.Check type="checkbox" label="I am not a robot" />
                    </Form.Group> */}
                      {/* <div className="mt-4">
                        <ReCAPTCHA
                          // Stage
                          sitekey="6LdCyDsbAAAAAJ-10hdF3UvVMuBjaB8kMUyPxf0l"
                          // Production
                          // sitekey="6LfKyjsbAAAAAF8a9eNXTwpPcVCgatRt50RZ0T7w"
                          onChange={onChange}
                          onExpired={onExpiredCaptcha}
                          ref={recaptchaRef}
                        />
                        {captchaError && (
                          <span className="input-error">
                            Please Accept Captcha
                          </span>
                        )}
                      </div> */}
                    </div>

                    <div className="text-center pt-5 pb-3">
                      <Button
                        //ref={btnRef}
                        variant="dark"
                        size="lg"
                        className="rounded-pill text-uppercase"
                        type="submit"
                        // onClick={timer}
                      >
                        Submit
                      </Button>
                      {errormsg && (
                        <h6 className="mt-4" style={{ color: "#ffd204" }}>
                          Your address details are not saved, please submit it
                          again.
                        </h6>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>

          <div className="">
            <Figure className="mb-0 form-stars-left-top position-absolute">
              <Figure.Image src={FormStarsLeftTop} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 form-stars-left-center position-absolute">
              <Figure.Image src={FormStarsLeftCenter} alt="Stars Left Center" />
            </Figure>
            <Figure className="mb-0 form-stars-left-bottom position-absolute">
              <Figure.Image src={FormStarsLeftBottom} alt="Stars Left Bottom" />
            </Figure>

            <Figure className="mb-0 form-stars-right-top position-absolute">
              <Figure.Image src={FormStarsRightTop} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 form-stars-right-center position-absolute">
              <Figure.Image
                src={FormStarsRightCenter}
                alt="Stars Right Center"
              />
            </Figure>
            <Figure className="mb-0 form-stars-right-bottom position-absolute">
              <Figure.Image
                src={FormStarsRightBottom}
                alt="Stars Right Bottom"
              />
            </Figure>
          </div>
        </section>
      {/* </ScrollableAnchor> */}
    </>
  )
}

export default WinnerMmsBundle
