import React from "react"

// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"

import { Container, Figure } from "react-bootstrap"

// Star Backgrounds - Mobile
import StarsLeftTopM from "../images/stars-left-top-m.png"
import StarsLeftBottomM from "../images/stars-left-bottom-m.png"
import StarsRightTopM from "../images/stars-right-top-m.png"
import StarsRightBottomM from "../images/stars-right-bottom-m.png"

// Star Backgrounds - Desktop
import StarsLeft from "../images/stars-left.png"
import StarsRight from "../images/stars-right.png"

// configureAnchors({ offset: -20 })

const WinnerFreePack = () => {
  return (
    <>
      {/* <ScrollableAnchor id={"main"}> */}
      <section className="main-content py-4 position-relative">
        <Container>
          <div className="text-white px-2">
            <h2 className="text-center text-uppercase my-3">
              Congrats,{" "}
              <span className="d-block">
                You've won a Free Pack of Confectionery
              </span>
            </h2>
            <h4 className="text-center text-uppercase mb-3">
              We've sent you an Email which includes a link to print off a
              coupon to take into participating retailers to claim your free
              pack of Confectionery.
            </h4>
          </div>
        </Container>
        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={StarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={StarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={StarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image src={StarsRightBottomM} alt="Stars Right Bottom" />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 stars-left position-absolute">
            <Figure.Image src={StarsLeft} alt="Stars Left" />
          </Figure>
          <Figure className="mb-0 stars-right position-absolute">
            <Figure.Image src={StarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </section>
      {/* </ScrollableAnchor> */}
    </>
  )
}

export default WinnerFreePack
